<template>
  <div>
    <div class="container mt-3 animated-container">
      <h1>{{ data.name }}</h1>

      <div v-for="product in data.products" :key="'product-' + product.id" class="row mb-4">
        <div class="col-12 col-md-6">
          <img
              v-if="mockupLinksByProduct[product.id] && mockupLinksByProduct[product.id][0] || product.product_thumb"
              :src="(mockupLinksByProduct[product.id] && mockupLinksByProduct[product.id][0]) || product.product_thumb"
              class="img-fluid w-100 mb-3"
          />

        </div>
        <div class="col-12 col-md-6">
          <h2 class="product-title mb-0">{{ product.name }}</h2>
          <span class="variant-name mt-0 mb-0">{{ product.variant_name }}</span>
          <div class="py-3">{{ getDiscountedPrice(product) }}€ per item</div>
          <div class="pb-3" v-if="product.short_description">{{ product.short_description }}
          </div>
          <div v-if="product.description"></div>
          <div class="row">
            <template>
              <div class="col-12 col-lg-6 mb-3">
                <SamTextField
                    label="Order quantity"
                    v-model="product.order_quantity"
                    :error="!product.order_quantity"
                    errorMessage="Order quantity is required"
                    :additionalText="product.split_sizes && product.split_sizes.length !== 0 ? 'Choose sizes' : ''"
                    :onClick="() => showSizeSplitModal(product)"
                    labelMarginBottomClass="mb-0"
                />
              </div>
            </template>
            <div class="col-12 col-lg-6 mb-3">
              <SamTextField
                  label="Unit price €"
                  :value="getDiscountedPrice(product)"
                  :error="!product.item_price"
                  errorMessage="Unit price is required"
                  additionalText="View discounts"
                  :onClick="() => showDiscountModal(product)"
                  :read-only="true"
                  labelMarginBottomClass="mb-0"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3">
              <SamLabel label="Branding*"/>
              <v-select
                  :options="product.branding_methods.map(method => ({
        label: method.combination_name,
        value: method.method
      }))"
                  placeholder="Select combination"
                  class="w-100 mb-1 sam-select"
                  v-model="selectedMethods[product.id]"
                  :multiple="true"
                  :closeOnSelect="false"
                  @input="(selected) => updateChosenByCustomer(product.id, selected)"
              />
              <BrandingCombinationError :validationObject="$v.selectedMethods" :productId="product.id"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <SamPrimaryButton text="Edit Mockup" @buttonAction="editMockup(product.id)"
                                :disabled="selectedMethods[product.id].length == 0"/>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <SamSecondaryButton text="Remove product from offer"
                                  @buttonAction="executeShowRemoveProductModal(product)"/>
            </div>
          </div>
          <div class="border-top border-bottom mt-3" style="cursor: pointer; padding-top: 12px; padding-bottom: 12px;">
            <div @click="toggleComments(product.id)" class="d-flex justify-content-between align-items-center">
              <SamSpan fontWeight="700" text="Product comments"/>
              <div style="cursor: pointer; line-height: 0;">
                <img
                    :src="showComments[product.id] ? require('@/assets/images/line.svg') : require('@/assets/images/cross.svg')"
                    alt="toggle comments" style="width: 16px; height: 16px;"/>
              </div>
            </div>
            <div v-if="showComments[product.id]" class="mt-2">
              <div v-for="comment in product.comments" :key="`product-${product.id}-comment-${comment.id}`"
                   class="mb-2">
                <div class="comment-date">
                  {{
                    (comment.created_by === 'OFFER EDIT PAGE' ? `${data.brm.first_name} ${data.brm.last_name}`: data.contact_firstname)
                  }}
                  {{
                    formatDateTimeCET(comment.created)
                  }}
                </div>
                <SamParagraph :text="comment.comment"/>
              </div>
              <div class="row mt-3 align-items-end">
                <div class="col-12 col-lg-6">
                  <SamTextField label="Leave comment" v-model="newComment[product.id]"/>
                </div>
                <div class="col-12 col-lg-6">
                  <SamSecondaryButton text="Save comment" @buttonAction="saveComment(product.id)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="border-bottom mb-4"></div>

      <div class="container px-0">
        <h1>Delivery Address</h1>
        <div class="row mb-4" v-if="deliveryAddress">
          <div class="col-12 col-md-3">
            <SamTextField
                label="Address name*"
                v-model="deliveryAddress.company_name"
                :error="!$v.deliveryAddress.company_name.$pending && !$v.deliveryAddress.company_name.required"
                errorMessage="Address name is required."
            />
          </div>
          <div class="col-12 col-md-3">
            <SamTextField
                label="Street*"
                v-model="deliveryAddress.address"
                :error="!$v.deliveryAddress.address.$pending && !$v.deliveryAddress.address.required"
                errorMessage="Street is required."
            />
          </div>
          <div class="col-12 col-md-3">
            <SamTextField
                label="Postal code*"
                v-model="deliveryAddress.zip_code"
                :error="!$v.deliveryAddress.zip_code.$pending && !$v.deliveryAddress.zip_code.required"
                errorMessage="Postal code is required."
            />
          </div>
          <div class="col-12 col-md-3">
            <SamTextField
                label="City*"
                v-model="deliveryAddress.city"
                :error="!$v.deliveryAddress.city.$pending && !$v.deliveryAddress.city.required"
                errorMessage="City is required."
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <SamLabel label="Country*"/>
            <v-select
                :options="countries"
                v-model="selectedCountry"
                placeholder="Select country"
                class="w-100 mb-4 sam-select"
                :class="{ 'is-invalid': !$v.selectedCountry.required && !$v.selectedCountry.$pending }"
            ></v-select>
            <div v-if="!$v.selectedCountry.required && !$v.selectedCountry.$pending" class="text-danger">
              Country is required.
            </div>
          </div>
          <div class="col-12 col-md-3">
            <SamTextField
                label="Recipient mail*"
                v-model="data.contact_email"
                :error="!$v.data.contact_email.$pending && !$v.data.contact_email.required"
                errorMessage="Recipient email is required."
            />
          </div>
          <div class="col-12 col-md-3">
            <SamTextField
                label="Recipient phone*"
                v-model="data.contact_phone"
                :error="!$v.data.contact_phone.$pending && !$v.data.contact_phone.required"
                errorMessage="Recipient phone is required."
            />
          </div>
          <div class="col-12 col-md-3">
            <SamTextField
                label="Your desired delivery date*"
                v-model="data.desired_delivery_date"
                type="date"
                :minDate="minDesiredDeliveryDate"
                :replaceWithCustomIcon="true"
                :error="!$v.data.desired_delivery_date.$pending && !$v.data.desired_delivery_date.required"
                errorMessage="Delivery date is required."
            />
          </div>
        </div>
      </div>


    </div>

    <div class="summary-section container-fluid">
      <div class="container py-5">
        <h1>Summary</h1>
        <div class="row">
          <div class="col-6 col-md-6">
            <div>
              <SamLabel label="Line item"/>
              <div v-if="data.products && data.products.length">

                <div v-for="product in data.products" :key="'product-branding-' + product.id">
                  <SamSpan :truncateText="true" :key="product.id + '-product-title'"
                           :text="`${product.name} - ${product.variant_name}`"/>
                  <div v-for="variant in product.variant_images" :key="'branding-variant-' + variant.id">
                    <div v-if="variant.branding_position.chosen_by_customer">
                      <SamSpan :truncateText="true"
                               :text="`${product.name} - ${product.variant_name} - ${variant.branding_position.combination_name} - Setup fee`"/>
                      <SamSpan :truncateText="true"
                               :text="`${product.name} - ${product.variant_name} - ${variant.branding_position.combination_name}`"/>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="data.line_items && data.line_items.length">
                <SamSpan v-for="item in data.line_items"
                         :key="'line-item-' + item.id"
                         :text="item.description.charAt(0).toUpperCase() + item.description.slice(1)"/>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-2">
            <div class="d-flex flex-column align-items-end align-items-md-start">
              <div>
                <SamLabel label="Unit price x quantity"/>
              </div>
              <div v-if="data.products && data.products.length">

                <div v-for="product in data.products" :key="'product-price-' + product.id">
                  <SamSpan :key="product.id + '-unit-price'"
                           :text="`${getDiscountedPrice(product)}€ x ${product.order_quantity}`"/>
                  <div v-for="variant in product.variant_images" :key="'price-variant-' + variant.id">
                    <div v-if="variant.branding_position.chosen_by_customer">
                      <SamSpan :text="`${variant.branding_position.setup_fee}€`"/>
                      <SamSpan :text="`${variant.branding_position.price}€ x ${product.order_quantity}`"/>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="data.line_items && data.line_items.length">
                <SamSpan v-for="item in data.line_items" :key="'line-item-price-' + item.id"
                         :text="`${item.unit_price}€ x ${item.quantity}`"/>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-right d-none d-sm-block">
            <SamLabel label="Subtotal"/>

            <div v-if="data.products && data.products.length">
              <SamSpan v-for="product in data.products" :key="'subtotal-product-' + product.id"
                       :text="`${(getDiscountedPrice(product) * product.order_quantity).toFixed(2)}€`"/>
              <div v-for="product in data.products" :key="'subtotal-branding-' + product.id">
                <div v-for="variant in product.variant_images" :key="'subtotal-variant-' + variant.id">
                  <div v-if="variant.branding_position.chosen_by_customer">
                    <SamSpan :text="`${variant.branding_position.setup_fee}€`"/>
                    <SamSpan :text="`${(variant.branding_position.price * product.order_quantity).toFixed(2)}€`"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="data.line_items && data.line_items.length" style="min-width: 19px;">
              <SamSpan v-for="item in data.line_items" :key="'line-item-subtotal-' + item.id"
                       :text="`${(item.unit_price * item.quantity).toFixed(2)}€`"/>
            </div>
          </div>
        </div>


        <div class="row mt-4">
          <div class="col-md-12 d-flex justify-content-between align-items-center">
            <SamSpan class="total-text fw-semibold" :text="`Total (VAT ${data.vat_percentage} %)`"/>
            <SamSpan class="total-text fw-semibold" :text="`${calculateTotal()}€`"/>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row py-4 mx-0 border-bottom">
        <div class="col-6 col-md-3 px-0">
          <SamLabel label="Estimated delivery date"/>
          <SamSpan :text="formatDate(data.estimated_delivery_date)"/>
        </div>
        <div class="col-6 col-md-3 px-0">
          <SamLabel label="Delivery terms"/>
          <SamSpan :text="getDeliveryTerms(data.delivery_terms)"/>
        </div>
        <div class="col-6 col-md-3 px-0">
          <SamLabel label="Payment terms"/>
          <SamSpan :text="getPaymentTerms(data.payment_terms)"/>
        </div>
        <div class="col-6 col-md-3 px-0">
          <SamLabel label="Offer expiry date"/>
          <SamSpan :text="formatDate(data.project_expiry_date)"/>
        </div>
      </div>
    </div>

    <div class="container pb-5">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="col-12 col-md-6 pl-0 pr-0">
            <div class="row">
              <div class="col-12 col-md-6 mb-3 mb-0 mb-md-0">
                <button class="btn btn-success custom-button w-100 mt-3 mt-md-3 cursor-pointer" @click="confirmOrder">
                  Confirm Order
                </button>
              </div>
              <div class="col-12 col-md-6 pl-md-1 pr-md-6">
                <button class="btn btn-danger custom-button w-100 mt-1 mt-md-3 cursor-pointer" @click="showRejectModal">
                  Decline Offer
                </button>
              </div>
            </div>
          </div>


          <div class="mt-4">
            <SamLabel label="Questions? I’m happy to help!"/>
            <SamSpan :text="data.brm.first_name + ' ' + data.brm.last_name"/>
            <SamSpanLink :text="data.brm.email" :link="'mailto:' + data.brm.email"/>
            <SamSpan :text="data.brm.phone"/>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
    </div>
    <SamModal
        v-if="currentProduct"
        :title="'Remove product?'"
        :showExportModal="showRemoveProductModal"
        @close="closeRemoveProductModal"
        @save="confirmRemoveProduct"
        :saveButtonText="'Remove product'"
        :isAlert="true"
        :alignButtons="true"
        :saveButtonClass="'btn-danger'"
    >
      <p>Are you sure you want to remove {{ currentProduct.name }} – {{ currentProduct.variant_name }} from this
        offer?</p>
    </SamModal>

    <SamModal
        :showExportModal="showLastProductModal"
        :isSaveHidden="true"
        id="lastProductModal"
        title="Remove product from offer"
        @close="showLastProductModal = false"
    >
      <p>Offer must have at least one product.</p>
      <p>If you want to see previously saved products, please refresh the page.</p>
      <p>Or if you want Framme to add more products to the offer, please write a product comment.</p>
    </SamModal>

    <SamModal
        :title="'Decline offer?'"
        :showExportModal="showModal"
        @close="closeModal"
        @save="handleReject"
        :saveButtonText="'Decline offer'"
        :isAlert="true"
        :alignButtons="true"
    >
      <div>
        <p>Are you sure you want to decline this offer? If so, could you provide us a reason so that we can improve how
          we work?</p>
        <SamLabel label="Reason for declining offer*"/>
        <div class="row-cols-md-2">
          <v-select
              v-model="selectedReason"
              :options="reasons"
              placeholder="Select"
              class="w-100 mb-4"
          ></v-select>
        </div>
      </div>
    </SamModal>
    <SamModal
        :title="'Confirm Order'"
        :showExportModal="showConfirmOrderModal"
        @close="closeConfirmOrderModal"
        @save="confirmOrderInModal"
        :saveButtonText="'Confirm order'"
        :isAlert="false"
        :alignButtons="true"
        :isSaveDisabled="!allLogosUploaded"
    >
      <div>

        <div v-if="allLogosUploaded">
          <p>
            By confirming the order, you agree to the following <a href="https://framme.com/pages/legal-stuff"
                                                                   target="_blank">terms and conditions</a> governing
            the branding process and approval of mockups and print files. These terms are essential to ensure that we
            can deliver a product that meets your expectations.
          </p>
        </div>
        <div v-else>
          <p>
            Please upload your logo or artwork for:
          </p>
          <ul>
            <div v-for="product in data.products" :key="product.id">
              <span v-for="variant in product.variant_images" :key="variant.id">
                <li v-if="variant.branding_position.chosen_by_customer && !variant.branding_position.logo_link">
                  {{ product.name }} – {{ variant.branding_position.combination_name }}
                </li>
              </span>
            </div>
          </ul>
          <p>
            before confirming your order to proceed with the order and branding process.
          </p>
        </div>
      </div>
    </SamModal>

    <template>
      <SamModal :title="'Split order quantity'" :alignButtons="true" :showExportModal="showSizeSplit"
                @close="closeSizeSplitModal" @save="saveSizeSplit(currentProduct ? currentProduct.id : null)">
        <div class="row">
          <div v-for="(quantity, size) in sizeSplit" :key="size" class="col-12 col-md-4">
            <SamLabel :label="size"/>
            <SamTextField v-model.number="sizeSplit[size]" type="number"/>
          </div>
        </div>
        <div v-if="splitErrors[currentProduct && currentProduct.id]" class="error-message">
          {{ splitErrors[currentProduct.id] }}
        </div>
      </SamModal>
    </template>

    <SamSimpleModal
        :title="'Bulk discounts for ' + (currentProduct ? currentProduct.name : '')"
        :showExportModal="showDiscounts"
        @close="closeDiscountModal"
        :alignButtons="false"
        :blackCloseButton="true"
        :closeButtonText="'Close'"
    >
      <div class="col-12 col-md-6 pl-0" v-if="currentProduct">
        <table v-if="currentProduct.bulk_discounts && currentProduct.bulk_discounts.length > 0"
               class="table table-borderless">
          <thead class="mt-0 mb-0">
          <tr>
            <th class="pt-0 pl-0 pb-0 col-6 col-md-auto">Order quantity</th>
            <th class="pt-0 pb-0 col-6 col-md-auto">Unit price (€)</th>
          </tr>
          </thead>
          <tbody>
          <tr class="mb-0 mt-0" v-for="discount in currentProduct.bulk_discounts" :key="discount.id">
            <td class="pt-0 pl-0 pb-0 col-6 col-md-auto">{{ discount.start_quantity }}-{{ discount.end_quantity }}</td>
            <td class="pt-0 pb-0 col-6 col-md-auto">{{ discount.price_per_unit }}</td>
          </tr>
          </tbody>
        </table>


        <div v-else class="no-discounts">
          No bulk discounts are available.
        </div>
      </div>
      <div v-else class="no-product">
        No product selected.
      </div>
    </SamSimpleModal>


    <SamMockupModal
        :title="'Edit Mockup'"
        :showExportModal="showMockupModal"
        @close="closeAndRestartData(null)"
        :alignButtons="false"
        :closeButtonText="'Close'"
    >
      <div class="container-fluid" v-if="currentProduct">
        <div class="row">
          <div class="col-md-6 p-0" style="position: relative;">
            <div v-if="currentImage" class="image-container" style="position: relative;">
              <div ref="konvaContainer" style="width: 100%; height: 100%"></div>
            </div>
            <div v-else>No mockup available</div>
            <input type="file" ref="fileInput" @change="handleFileUpload" accept=".svg, .pdf" style="display: none;"/>

          </div>

          <div class="col-md-6">
            <h1 class="mb-0">{{ currentProduct.name }}</h1>
            <span class="variant-name mt-0 mb-0">{{ currentProduct.variant_name }}</span>
            <ul
                class="nav nav-tabs"
                id="variantTabs"
                role="tablist"
                v-if="currentProduct.variant_images.filter(variant => variant.branding_position.chosen_by_customer).length > 0"
            >
              <li
                  class="nav-item"
                  v-for="(variant, index) in currentProduct.variant_images.filter(variant => variant.branding_position.chosen_by_customer)"
                  :key="`variant-tab-${currentProduct.id}-${index}`"
              >
                <a
                    class="nav-link"
                    :class="{ active: index === activeTab }"
                    @click="activeTab = index"
                    :id="'variant-tab-' + index"
                    data-toggle="tab"
                    :href="'#variant' + index"
                    role="tab"
                    aria-controls="'variant' + index"
                    :aria-selected="index === activeTab"
                >
                  {{ variant.branding_position.branding_position_name }}
                </a>
              </li>
            </ul>
            <div v-else>No selected variants</div>
            <div
                class="tab-content"
                id="variantTabContent"
                v-if="currentProduct.variant_images.filter(variant => variant.branding_position.chosen_by_customer).length > 0"
            >

              <div
                  class="tab-pane fade"
                  v-for="(variant, index) in currentProduct.variant_images.filter(variant => variant.branding_position.chosen_by_customer)"
                  :key="'variant-content-' + index + `${variant.id}`"
                  :class="{ show: index === activeTab, active: index === activeTab }"
                  :id="'variant' + index"
                  role="tabpanel"
                  :aria-labelledby="'variant-tab-' + index"
              >
                <p class="mt-2">
                  Design your product within {{ variant.branding_position.width_mm }}mm x
                  {{ variant.branding_position.height_mm }}mm print area. We recommend digital printing placed to top
                  right.
                </p>
                <div class="row">
                  <div class="col-12 col-lg-6 mb-3">
                    <SamLabel :label="`Method (${variant.branding_position.price}€ per item)`"/>
                    <v-select
                        :options="[{ id: variant.method_id, label: currentProduct.branding_methods.find(method => method.method === variant.method_id).name }]"
                        placeholder="Select method"
                        class="w-100 mb-4 sam-select"
                        :reduce="option => option.id"
                        v-model="variant.method_id"
                        :multiple="false"
                        :disabled="true"
                    />
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <SamLabel label="Placement"/>
                    <v-select
                        :options="placementOptions"
                        placeholder="Select placement"
                        class="w-100 mb-4 sam-select"
                        v-model="selectedPlacement"
                        :multiple="false"
                        @input="setLogoPosition"
                    />
                  </div>
                </div>

                <div class="row">

                  <div class="col-12 col-lg-12 mb-3">
                    <SamLabel label="Upload your logo or artwork*"/>
                    <SamSecondaryButton text="Choose file"
                                        @buttonAction="uploadSVGLogo(variant.branding_position.branding_method_id)"/>
                  </div>

                </div>
                <div class="row sam-top-border pt-4 ml-1 mr-1">
                </div>
                <div class="row ">
                  <div class="col-6 col-lg-6 mb-3">
                    <SamPrimaryButton :text="'Save'" @buttonAction="saveAndApproveMockup(variant)"/>
                  </div>
                  <div class="col-6 col-lg-6 mb-3">
                    <SamSecondaryButton :text="'Close'" @buttonAction="closeAndRestartData(variant)" :loading="resetInProgress"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SamMockupModal>
  </div>
</template>

<script>
import Konva from 'konva';
import SamLabel from "@/components/SamLabel.vue";
import SamSpan from "@/components/table/SamSpan.vue";
import SamPrimaryButton from "@/components/SamPrimaryButton.vue";
import SamSecondaryButton from "@/components/SamSecondaryButton.vue";
import SamTextField from "@/components/SamTextField.vue";
import SamSpanLink from "@/components/SamSpanLink.vue";
import SamParagraph from "@/components/SamParagraph.vue";
import vSelect from 'vue-select';
import SamModal from '@/components/SamModal.vue';
import SamSimpleModal from '@/components/SamSimpleModal.vue';
import SamMockupModal from "@/modules/offer/components/SamMockupModal.vue";
import * as ISO3166 from "iso-3166-1";
import {required} from 'vuelidate/lib/validators'
import BrandingCombinationError from "@/modules/offer/components/BrandingCombinationError.vue";

export default {
  name: "OfferDetailsPage",
  components: {
    BrandingCombinationError,
    SamMockupModal,
    SamSimpleModal,
    SamLabel,
    SamSpan,
    SamPrimaryButton,
    SamSecondaryButton,
    SamTextField,
    SamSpanLink,
    SamParagraph,
    vSelect,
    SamModal,
  },
  props: ["data", "headerStyles", "buttonStyles", "isMultiInvitation", "offerJustConfirmed"],
  data() {
    return {
      freshDataSrc: null,
      resetInProgress: false,
      currentProductDataCopy: null,
      showRemoveProductModal: false,
      showLastProductModal: false,
      currentTransformer: null,
      imageLoaded: false,
      rectangleReference: null,
      showConfirmOrderModal: false,
      brandingMethodSelectedDuringFileUploadId: null,
      scale: 100,
      rectX: 0,
      rectY: 0,
      rectWidth: 0,
      rectHeight: 0,
      rectAngle: 0,
      placementOptions: [
        {id: 1, label: 'Top left'},
        {id: 2, label: 'Top middle'},
        {id: 3, label: 'Top right'},
        {id: 4, label: 'Middle left'},
        {id: 5, label: 'Middle center'},
        {id: 6, label: 'Middle right'},
        {id: 7, label: 'Bottom left'},
        {id: 8, label: 'Bottom middle'},
        {id: 9, label: 'Bottom right'}
      ],
      selectedPlacement: null,
      selectedMethods: this.data.products.reduce((acc, product) => {
        acc[product.id] = [];
        return acc;
      }, {}),
      splitErrors: {},
      showComments: this.data.products.reduce((acc, product) => {
        acc[product.id] = product.comments && product.comments.length > 0;
        return acc;
      }, {}),
      newComment: {},
      showModal: false,
      showSizeSplit: false,
      showDiscounts: false,
      showMockupModal: false,
      currentProduct: null,
      activeTab: 0,
      selectedReason: null,
      reasons: [],
      sizeSplit: {},
      quantitySplit: {},
      deliveryAddress: this.data.addresses.find(address => address.delivery_address) || {
        company_name: "",
        address: "",
        zip_code: "",
        city: "",
        country: null
      },
      countries: ISO3166.all().map(country => ({
        label: country.country,
        value: country.alpha2
      })),
      svgLogo: null,
      stage: null,
      layer: null,
      prevPosition: {x: 0, y: 0},
      printWidth: 120,
      current_logo_x_axis: null,
      current_logo_y_axis: null,
      current_logo_x_width: null,
      current_logo_y_width: null,
      current_logo_width_mm: null,
      current_logo_height_mm: null,
      current_logo_original_width: null,
      current_logo_original_height: null,
      current_logo_minimum_scale: null,
      current_logo_maximum_scale: null,
      current_logo_scale: null,
      current_logo_angle: null,
      current_product: null,
      previous_scale: null,
      naturalImageHeightScale: null,
      naturalImageWidthScale: null,
      selectedCountry: null
    };
  },
  created() {
    this.initializeSelectedMethods();
    this.fetchDeclineReasons();
  },
  validations() {
    const validations = {
      deliveryAddress: {
        company_name: {required},
        address: {required},
        zip_code: {required},
        city: {required},
      },
      selectedCountry: {required},
      data: {
        contact_email: {required},
        contact_phone: {required},
        desired_delivery_date: {required},
      },
      selectedMethods: {},
    };
    this.data.products.forEach(product => {
      validations.selectedMethods[product.id] = {
        isNotEmpty(value) {
          return value && value.length > 0;
        }
      };
    });

    return validations;
  },
  mounted() {
    this.$v.$touch();
    this.selectedCountry = ISO3166.whereAlpha2(this.deliveryAddress.country).country
  },
  methods: {
    executeShowRemoveProductModal(product) {
      if (this.data.products && this.data.products.length === 1) {
        this.showLastProductModal = true;
      } else {
        this.currentProduct = product;
        this.showRemoveProductModal = true;
      }
    },

    confirmRemoveProduct() {
      this.data.products = this.data.products.filter(product => product.id !== this.currentProduct.id);
      this.closeRemoveProductModal();
    },

    closeRemoveProductModal() {
      this.showRemoveProductModal = false;
      this.currentProduct = null;
    },
    moveToThankYouPage() {
      this.$emit('toThankYouPage');
    },
    updateSizeSplit(product) {
      const totalOrderQuantity = product.order_quantity;
      const sizeCount = product.split_sizes.length;
      const quantityPerSize = Math.floor(totalOrderQuantity / sizeCount);
      const remainder = totalOrderQuantity % sizeCount;

      product.split_sizes.forEach(sizeSplit => {
        sizeSplit.quantity = quantityPerSize;
      });

      for (let i = 0; i < remainder; i++) {
        product.split_sizes[i].quantity += 1;
      }

      this.$forceUpdate();
    },
    loadSavedProperties(variantImage) {

      let naturalHeightFactor = 1 / this.naturalImageHeightScale;
      let naturalWidthFactor = 1 / this.naturalImageWidthScale;


      this.current_logo_width_mm = variantImage.branding_position.logo_width_mm;
      this.current_logo_height_mm = variantImage.branding_position.logo_height_mm;
      this.current_logo_minimum_scale = variantImage.branding_position.logo_minimum_scale / naturalWidthFactor;
      this.current_logo_maximum_scale = variantImage.branding_position.logo_maximum_scale / naturalWidthFactor;
      this.current_logo_scale = (variantImage.branding_position.logo_x_width /naturalWidthFactor) / variantImage.branding_position.logo_original_width;

      this.current_logo_x_axis = variantImage.branding_position.logo_x_axis / naturalWidthFactor;
      this.current_logo_y_axis = variantImage.branding_position.logo_y_axis / naturalHeightFactor;

      this.current_logo_x_width = variantImage.branding_position.logo_x_width / naturalWidthFactor;
      this.current_logo_y_width = variantImage.branding_position.logo_y_width / naturalHeightFactor;

      this.current_logo_original_width = variantImage.branding_position.logo_original_width;
      this.current_logo_original_height = variantImage.branding_position.logo_original_height;
      this.current_logo_angle = variantImage.branding_position.logo_angle;


    },
    async clearSavedProperties() {

      this.current_logo_width_mm = null;
      this.current_logo_height_mm = null;
      this.current_logo_minimum_scale = null;
      this.current_logo_maximum_scale = null;
      this.current_logo_scale = null;

      this.current_logo_x_axis = null;
      this.current_logo_y_axis = null;

      this.current_logo_x_width = null;
      this.current_logo_y_width = null;

      this.current_logo_original_width = null;
      this.current_logo_original_height = null;
      this.current_logo_angle = null;

    },
    clearVariantProperties(variantImage) {
      const propertiesToClear = [
        'logo_width_mm',
        'logo_height_mm',
        'logo_minimum_scale',
        'logo_maximum_scale',
        'logo_scale',
        'logo_x_axis',
        'logo_y_axis',
        'logo_x_width',
        'logo_y_width',
        'logo_original_width',
        'logo_original_height',
        'logo_angle'
      ];

      propertiesToClear.forEach(prop => {
        if (variantImage.branding_position && variantImage.branding_position.hasOwnProperty(prop)) {
          variantImage.branding_position[prop] = null;
        }
      });
    },
    hasVariantAllRequiredProperties(variantImage) {
      const requiredProperties = [
        'logo_width_mm',
        'logo_height_mm',
        'logo_minimum_scale',
        'logo_maximum_scale',
        'logo_scale',
        'logo_x_axis',
        'logo_y_axis',
        'logo_x_width',
        'logo_y_width',
        'logo_original_width',
        'logo_original_height',
        'logo_angle'
      ];

      const missingProperties = [];

      requiredProperties.forEach(prop => {
        if (
            !variantImage.branding_position ||
            !variantImage.branding_position.hasOwnProperty(prop) ||
            variantImage.branding_position[prop] === null ||
            variantImage.branding_position[prop] === undefined
        ) {
          //console.log("missing prop", prop)
          missingProperties.push(prop);
        }
      });

      return missingProperties.length === 0;
    },
    dataURLtoFile(dataurl, filename) {
      if (!dataurl) {
        throw new Error("Invalid dataURL");
      }

      const arr = dataurl.split(',');

      if (arr.length < 2) {
        throw new Error("DataURL seems to be malformed");
      }

      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type: mime});
    },
    async uploadImage(imageFile, brandingMethodId) {
      const formData = new FormData();
      formData.append("branding_method_id", brandingMethodId);
      formData.append("mockup_file", imageFile);

      const token = this.data.token;
      const url = `${process.env.VUE_APP_API_URL}/api/projects/open/upload-mockup-file/${token}/`;

      try {
        const response = await fetch(url, {
          method: "PUT",
          body: formData,
        });
        const data = await response.json();
        if (data.status === "file uploaded") {
          this.current_variant.branding_position.mockup_link = data.mockup_link;
        } else {
          // console.error("Failed to upload the mockup file:", data);
        }
      } catch (error) {
        // console.error("Error uploading the artwork file:", error);
      }
    },
    async saveAndApproveMockup(variantImage) {
     //console.log("branding method id", variantImage.branding_position.branding_method_id)

      if (this.currentTransformer) {
        this.currentTransformer.destroy();
        this.currentTransformer = null;
      }
      if (this.rectangleReference) {
        this.rectangleReference.destroy();
        this.rectangleReference = null;
      }
      const dataURL = await this.stage.toDataURL({
        mimeType: 'image/png',
        quality: 1.0
      });

      const newThumbnailFile = this.dataURLtoFile(dataURL, 'product-thumbnail.png');
      this.currentProduct.product_thumb_alt = URL.createObjectURL(newThumbnailFile);
      await this.uploadImage(newThumbnailFile, variantImage.branding_position.branding_method_id);


      await this.savePropsInData(variantImage)



      await this.clearSavedProperties();
      if (this.stage) {
        this.stage.destroy();
        this.stage = null;
        this.layer = null;
      }


      this.showMockupModal = false;
      this.currentProduct = null;


    },
    async closeAndRestartData(variantImage) {
      if(variantImage != null) {
        //console.log("variantImage", variantImage.id)
      }
      if (this.currentTransformer) {
        this.currentTransformer.destroy();
        this.currentTransformer = null;
      }
      if (this.rectangleReference) {
        this.rectangleReference.destroy();
        this.rectangleReference = null;
      }
      this.resetInProgress = true;
      await this.restartVariantsData();
      await this.clearSavedProperties();
      this.resetInProgress = false;
      if (this.stage) {
        this.stage.destroy();
        this.stage = null;
        this.layer = null;
      }


      this.showMockupModal = false;
      this.currentProduct = null;


    },
    async restartVariantsData() {
      const variantsToUpdate = this.currentProduct.variant_images.filter(
          (variant) => variant.branding_position.chosen_by_customer === true
      );

      //console.log("Variants to update", variantsToUpdate)

      for (const variant of variantsToUpdate) {


        const matchingVariant = this.currentProductDataCopy.variant_images.find(
            (copyVariant) => copyVariant.id === variant.id
        );


        //console.log("Matching Variant", matchingVariant)

        if (matchingVariant) {
          // Upload logo file if available
          const logoFormData = new FormData();
          logoFormData.append("branding_method_id", variant.branding_position.branding_method_id);

          if (matchingVariant.branding_position.logo_link) {
            const logoFile = await this.getFileFromUrl(matchingVariant.branding_position.logo_link);
            logoFormData.append("artwork_file", logoFile);
          }

          const logoToken = this.data.token;
          const logoUrl = `${process.env.VUE_APP_API_URL}/api/projects/open/upload-artwork-file/${logoToken}/`;

          try {
            const logoResponse = await fetch(logoUrl, {
              method: "PUT",
              body: logoFormData,
            });

            const logoData = await logoResponse.json();
            if (logoData.status === "file uploaded") {
              variant.branding_position.logo_link = logoData.logo_link;
            } else {
              // console.error("Failed to upload logo file:", logoData);
            }
          } catch (error) {
            // console.error("Error uploading the logo file:", error);
          }

          // Upload mockup file if available
          const mockupFormData = new FormData();
          mockupFormData.append("branding_method_id", variant.branding_position.branding_method_id);

          if (matchingVariant.branding_position.mockup_link) {
            const mockupFile = await this.getFileFromUrl(matchingVariant.branding_position.mockup_link);
            mockupFormData.append("mockup_file", mockupFile);
          }

          const mockupToken = this.data.token;
          const mockupUrl = `${process.env.VUE_APP_API_URL}/api/projects/open/upload-mockup-file/${mockupToken}/`;

          try {
            const mockupResponse = await fetch(mockupUrl, {
              method: "PUT",
              body: mockupFormData,
            });

            const mockupData = await mockupResponse.json();
            if (mockupData.status === "file uploaded") {
              variant.branding_position.mockup_link = mockupData.mockup_link;
            } else {
              //console.error("Failed to upload mockup file:", mockupData);
            }
          } catch (error) {
            //console.error("Error uploading the mockup file:", error);
          }


          variant.branding_position.logo_x_axis = matchingVariant.branding_position.logo_x_axis;
          variant.branding_position.logo_y_axis = matchingVariant.branding_position.logo_y_axis;
          variant.branding_position.logo_x_width = matchingVariant.branding_position.logo_x_width;
          variant.branding_position.logo_y_width = matchingVariant.branding_position.logo_y_width;
          variant.branding_position.logo_width_mm = matchingVariant.branding_position.logo_width_mm;
          variant.branding_position.logo_height_mm = matchingVariant.branding_position.logo_height_mm;
          variant.branding_position.logo_original_width = matchingVariant.branding_position.logo_original_width;
          variant.branding_position.logo_original_height = matchingVariant.branding_position.logo_original_height;
          variant.branding_position.logo_angle = matchingVariant.branding_position.logo_angle;
          variant.branding_position.logo_minimum_scale = matchingVariant.branding_position.logo_minimum_scale;
          variant.branding_position.logo_maximum_scale = matchingVariant.branding_position.logo_maximum_scale;
          variant.branding_position.logo_scale = matchingVariant.branding_position.logo_scale;

          //console.log("Updated variant with data from matching variant:", variant);

          const requestData = {
            branding_method_id: variant.branding_position.branding_method_id,
            chosen_by_customer: variant.branding_position.chosen_by_customer,
            logo_x_axis: variant.branding_position.logo_x_axis,
            logo_y_axis: variant.branding_position.logo_y_axis,
            logo_x_width: variant.branding_position.logo_x_width,
            logo_y_width: variant.branding_position.logo_y_width,
            logo_width_mm: variant.branding_position.logo_width_mm,
            logo_height_mm: variant.branding_position.logo_height_mm,
            logo_original_width: variant.branding_position.logo_original_width,
            logo_original_height: variant.branding_position.logo_original_height,
            logo_minimum_scale: variant.branding_position.logo_minimum_scale,
            logo_maximum_scale: variant.branding_position.logo_maximum_scale,
            logo_scale: variant.branding_position.logo_scale,
            logo_initial_scale: null,
            logo_angle: variant.branding_position.logo_angle,
          };

          //console.log("Request Data prepared for server:", requestData);

          await this.updateBrandingMethod(requestData);
        } else {
          //console.log(`No matching variant found in currentProductDataCopy for variant ID: ${variant.id}`);
        }
      }
    },
    async getFileFromUrl(url) {
      //console.log("getting file from url", url)
      try {
        const response = await fetch(url, { mode: 'cors', cache: 'no-cache' });
        if (response.status != 200) {
          throw new Error('Failed to fetch file from URL ' + url);
        } else {
          //console.log("fetched " + url)
        }


        const blob = await response.blob();


        const urlSegments = url.split('/');
        const fileName = urlSegments[urlSegments.length - 1] || 'downloaded-file';

        const file = new File([blob], fileName, { type: blob.type });

        return file;
      } catch (error) {
        // console.error('Error fetching the file:', error);
        throw error;
      }
    },
    async savePropsInData(variantImage) {
      if (this.current_logo_angle != null) {

      //console.log("branding method id", variantImage.branding_position.branding_method_id)

      const factor = this.current_logo_y_width / variantImage.height;
      let naturalHeightFactor = 1 / this.naturalImageHeightScale;
      let naturalWidthFactor = 1 / this.naturalImageWidthScale;


      this.current_logo_height_mm = factor * this.current_variant.branding_position.height_mm * naturalHeightFactor;

      variantImage.branding_position.logo_width_mm = this.current_logo_width_mm;
      variantImage.branding_position.logo_height_mm = this.current_logo_height_mm;
      variantImage.branding_position.logo_minimum_scale = this.current_logo_minimum_scale * naturalWidthFactor;
      variantImage.branding_position.logo_maximum_scale = this.current_logo_maximum_scale * naturalWidthFactor;
      variantImage.branding_position.logo_scale = this.current_logo_scale;


      variantImage.branding_position.logo_x_axis = this.current_logo_x_axis * naturalWidthFactor;
      variantImage.branding_position.logo_y_axis = this.current_logo_y_axis * naturalHeightFactor;


      variantImage.branding_position.logo_x_width = this.current_logo_x_width * naturalWidthFactor;
      variantImage.branding_position.logo_y_width = this.current_logo_y_width * naturalHeightFactor;

      variantImage.branding_position.logo_original_width = this.current_logo_original_width;
      variantImage.branding_position.logo_original_height = this.current_logo_original_height;
      variantImage.branding_position.logo_angle = this.current_logo_angle;


        const requestData = {
          branding_method_id: this.current_variant.branding_position.branding_method_id,
          chosen_by_customer: this.current_variant.branding_position.chosen_by_customer,
          logo_x_axis: this.current_logo_x_axis * naturalWidthFactor,
          logo_y_axis: this.current_logo_y_axis * naturalHeightFactor,
          logo_x_width: this.current_logo_x_width * naturalWidthFactor,
          logo_y_width: this.current_logo_y_width * naturalHeightFactor,
          logo_width_mm: this.current_logo_width_mm,
          logo_height_mm: this.current_logo_height_mm,
          logo_original_width: this.current_logo_original_width,
          logo_original_height: this.current_logo_original_height,
          logo_minimum_scale: this.current_logo_minimum_scale * naturalWidthFactor,
          logo_maximum_scale: this.current_logo_maximum_scale * naturalWidthFactor,
          logo_scale: this.current_logo_scale,
          logo_initial_scale: null,
          logo_angle: this.current_logo_angle,
        };

        // console.log("Natural Width Factor:", naturalWidthFactor);
        // console.log("Request Data:", requestData);

        await this.updateBrandingMethod(requestData);
      }

    },
    async updateBrandingMethod(requestData) {
      const token = this.data.token;
      const url = `${process.env.VUE_APP_API_URL}/api/branding-methods/open/update-by-token/${token}/`;

      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        //console.log('response update branding method')
        //console.log(response)
        //const data = await response.json();
        //console.log('response update branding method')
        //console.log(data)
        if (response.status === 200) {
          //console.log("Branding method updated successfully:", data);
        } else {
          // console.error("Failed to update branding method:", data);
        }
      } catch (error) {
        //console.error("Error updating branding method:", error);
      }
    },
    getDiscountedPrice(product) {
      let discountedPrice = product.item_price;

      if (product.bulk_discounts && product.bulk_discounts.length > 0) {
        let bestDiscountPrice = product.item_price;

        product.bulk_discounts.forEach(discount => {
          if (product.order_quantity >= discount.start_quantity && product.order_quantity <= discount.end_quantity) {
            discountedPrice = discount.price_per_unit;
          }
          bestDiscountPrice = discount.price_per_unit;
        });

        if (product.order_quantity > product.bulk_discounts[product.bulk_discounts.length - 1].end_quantity) {
          discountedPrice = bestDiscountPrice;
        }
      }

      return discountedPrice;
    },
    setLogoPosition(value) {
      this.selectedPlacement = value.label;

      if (!this.svgLogo) return;

      this.svgLogo.rotation(this.rectAngle);

      const rectTopLeft = this.getRotatedPoint(this.rectX, this.rectY, this.rectX, this.rectY, this.rectAngle);
      const rectTopRight = this.getRotatedPoint(this.rectX + this.rectWidth, this.rectY, this.rectX, this.rectY, this.rectAngle);
      const rectBottomLeft = this.getRotatedPoint(this.rectX, this.rectY + this.rectHeight, this.rectX, this.rectY, this.rectAngle);
      const rectBottomRight = this.getRotatedPoint(this.rectX + this.rectWidth, this.rectY + this.rectHeight, this.rectX, this.rectY, this.rectAngle);


      let x, y;

      const rectTopCenter = {
        x: (rectTopLeft.x + rectTopRight.x) / 2,
        y: (rectTopLeft.y + rectTopRight.y) / 2
      };
      const rectBottomCenter = {
        x: (rectBottomLeft.x + rectBottomRight.x) / 2,
        y: (rectBottomLeft.y + rectBottomRight.y) / 2
      };
      const rectCenter = {
        x: (rectTopLeft.x + rectBottomRight.x) / 2,
        y: (rectTopLeft.y + rectBottomRight.y) / 2
      };
      const rectMiddleLeft = {
        x: (rectTopLeft.x + rectBottomLeft.x) / 2,
        y: (rectTopLeft.y + rectBottomLeft.y) / 2
      };
      const rectMiddleRight = {
        x: (rectTopRight.x + rectBottomRight.x) / 2,
        y: (rectTopRight.y + rectBottomRight.y) / 2
      };

      switch (this.selectedPlacement) {
        case 'Top left': {
          x = rectTopLeft.x + 1;
          y = rectTopLeft.y + 1;
          break;
        }
        case 'Top middle': {
          let offsetX = -(this.svgLogo.width() / 2);
          let offsetY = 0;
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
          let translatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);
          x = rectTopCenter.x + translatedOffsetX + 1;
          y = rectTopCenter.y + translatedOffsetY + 1;
          break;
        }
        case 'Top right': {
          let offsetTopRightX = -this.svgLogo.width();
          //console.log(" TOP RIGHT width of logo: " + this.svgLogo.width())
          let offsetTopRightY = 0;
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetTopRightX * Math.cos(radians) - offsetTopRightY * Math.sin(radians);
          let translatedOffsetY = offsetTopRightX * Math.sin(radians) + offsetTopRightY * Math.cos(radians);
          x = rectTopRight.x + translatedOffsetX;
          y = rectTopRight.y + translatedOffsetY + 1;
          break;
        }
        case 'Middle left': {
          let offsetX = 0;
          let offsetY = -(this.svgLogo.height() / 2);
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
          let translatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);
          x = rectMiddleLeft.x + translatedOffsetX + 1;
          y = rectMiddleLeft.y + translatedOffsetY + 1;
          break;
        }
        case 'Middle center': {
          let offsetX = -(this.svgLogo.width() / 2);
          let offsetY = -(this.svgLogo.height() / 2);
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
          let translatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);
          x = rectCenter.x + translatedOffsetX + 1;
          y = rectCenter.y + translatedOffsetY + 1;
          break;
        }
        case 'Middle right': {
          let offsetX = -this.svgLogo.width();
          let offsetY = -(this.svgLogo.height() / 2);
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
          let translatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);
          x = rectMiddleRight.x + translatedOffsetX - 1;
          y = rectMiddleRight.y + translatedOffsetY - 1;
          break;
        }
        case 'Bottom left': {
          let offsetX = 0;
          let offsetY = -this.svgLogo.height();
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
          let translatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);
          x = rectBottomLeft.x + translatedOffsetX + 1;
          y = rectBottomLeft.y + translatedOffsetY - 1;
          break;
        }
        case 'Bottom middle': {
          let offsetX = -(this.svgLogo.width() / 2);
          let offsetY = -this.svgLogo.height();
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
          let translatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);
          x = rectBottomCenter.x + translatedOffsetX + 1;
          y = rectBottomCenter.y + translatedOffsetY - 1;
          break;
        }
        case 'Bottom right': {
          let offsetX = -this.svgLogo.width();
          let offsetY = -this.svgLogo.height();
          let radians = this.rectAngle * Math.PI / 180;
          let translatedOffsetX = offsetX * Math.cos(radians) - offsetY * Math.sin(radians);
          let translatedOffsetY = offsetX * Math.sin(radians) + offsetY * Math.cos(radians);
          x = rectBottomRight.x + translatedOffsetX - 1;
          y = rectBottomRight.y + translatedOffsetY - 1;
          break;
        }
        default: {
          x = rectTopLeft.x;
          y = rectTopLeft.y;
        }
      }

      this.svgLogo.position({x, y});
      this.layer.draw();
    },
    initializeSelectedMethods() {
      this.data.products.forEach(product => {
        this.selectedMethods[product.id] = product.variant_images
            .filter(variant => variant.branding_position.chosen_by_customer)
            .map(variant => {
              const foundMethod = product.branding_methods.find(method => method.method === variant.method_id);
              return foundMethod ? {label: foundMethod.combination_name, value: foundMethod.method} : undefined;
            })
            .filter(item => item !== undefined);
      });
    },
    updateChosenByCustomer(productId, selectedMethods) {
      const productIndex = this.data.products.findIndex(p => p.id === productId);
      if (productIndex === -1) return;

      const product = this.data.products[productIndex];
      let updatedProduct = JSON.parse(JSON.stringify(product));
      const selectedMethodIds = new Set(selectedMethods.map(method => method.value));

      updatedProduct.variant_images.forEach(variant => {
        const newChosenByCustomer = selectedMethodIds.has(variant.method_id);
        variant.branding_position.chosen_by_customer = newChosenByCustomer;

          const requestData = {
            branding_method_id: variant.branding_position.branding_method_id,
            chosen_by_customer: newChosenByCustomer,
            // logo_x_axis: null,
            // logo_y_axis: null,
            // logo_x_width: null,
            // logo_y_width: null,
            // logo_width_mm: null,
            // logo_height_mm: null,
            // logo_original_width: null,
            // logo_original_height: null,
            // logo_minimum_scale: null,
            // logo_maximum_scale: null,
            // logo_scale: null,
            // logo_initial_scale: null,
            // logo_angle: null,
          };

          // reset mockup file + logo

          this.updateBrandingMethod(requestData).then(() => {})

      });





      this.$set(this.data.products, productIndex, updatedProduct);

      this.$forceUpdate();
    },
    async fetchDeclineReasons() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/projects/open/decline-reasons/${this.$route.params.token}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.reasons = data.map(reason => ({
          label: reason.name,
          value: reason.id
        }));
      } catch (error) {
        //console.error('Error fetching decline reasons:', error);
      }
    },
    toggleComments(productId) {
      this.$set(this.showComments, productId, !this.showComments[productId]);
    },
    editMockup(productId) {
      const product = this.data.products.find(product => product.id === productId) || {};
      this.currentProductDataCopy = JSON.parse(JSON.stringify(product));
     // console.log("Product is edited", product)

      this.currentProduct = product;
      this.showMockupModal = true;

      if (this.filteredVariants.length > 0) {
        this.activeTab = 0;
      } else {
        this.activeTab = -1;
      }

      this.$nextTick(() => {
        const initialVariant = this.filteredVariants[this.activeTab];
        this.current_variant = initialVariant;
        if (initialVariant && initialVariant.branding_position.logo_link) {
          setTimeout(async () => {
            if (!this.layer || !this.stage) {
              setTimeout(async () => {
                await this.onImageLoad(initialVariant);
                await this.displayLogoOnCanvas(initialVariant.branding_position.logo_link, initialVariant);
              }, 50);
            } else {
              await this.onImageLoad(initialVariant);
              await this.displayLogoOnCanvas(initialVariant.branding_position.logo_link, initialVariant);
            }
          }, 50);
        }
      });
    },
    saveComment(productId) {
      if (!this.newComment[productId]) {
        this.$toastr.e('Please enter a comment before saving.');
        return;
      }

      const api_url = `${process.env.VUE_APP_API_URL}/api/comments/open/create-by-token/${this.$route.params.token}/`;
      const commentData = {
        comment: this.newComment[productId],
        created_by: "OFFER EDIT PAGE",
        product: productId
      };

      this.$http.post(api_url, commentData)
          .then(response => {

            this.data.products = this.data.products.map(product =>
                product.id === productId
                    ? {...product, comments: [...product.comments, response.data]}
                    : product
            );
            this.newComment[productId] = '';
            //console.log('Comment added successfully:', response.data);
          })
          .catch(() => {
            //console.error('Failed to add comment:', error);
          });
    },
    confirmOrder() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.showConfirmOrderModal = true;
      } else {
        this.$toastr.e("Fill in all required information.");
      }
    },
    confirmOrderInModal() {
      const api_url = `${process.env.VUE_APP_API_URL}/api/projects/open/confirm-order/${this.$route.params.token}/`;

      const modifiedData = JSON.parse(JSON.stringify(this.data));

      modifiedData.brm = this.data.brm.id;
      modifiedData.pm = this.data.pm.id;

      modifiedData.products.forEach(product => {
        delete product.product_thumb_alt;
        product.branding_methods.forEach(brandingMethod => {
          delete brandingMethod.artwork_file;

        });
      });


      this.$http.put(api_url, modifiedData)
          .then(() => {
            // console.log('Order confirmed successfully:', response.data);
            this.showConfirmOrderModal = false;
            this.moveToThankYouPage();
          })
          .catch(() => {
            //console.error('Failed to confirm order:', error);
            this.$toastr.e('Failed to confirm the order. Please try again.');
          });
    },
    closeConfirmOrderModal() {
      this.showConfirmOrderModal = false;
    },
    formatDate(dateString) {
      const options = {year: "numeric", month: "short", day: "numeric"};
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatDateTimeCET(dateString) {
      const utcDate = new Date(dateString + 'Z');

      const isDST = (utcDate.getMonth() > 2 && utcDate.getMonth() < 9) ||
          (utcDate.getMonth() === 2 && utcDate.getDate() >= 31 - (new Date(utcDate.getFullYear(), 2, 31).getDay())) ||
          (utcDate.getMonth() === 9 && utcDate.getDate() < 31 - (new Date(utcDate.getFullYear(), 9, 31).getDay()));

      const offsetHours = isDST ? 2 : 1;


      utcDate.setHours(utcDate.getUTCHours() + offsetHours);


      const day = String(utcDate.getDate()).padStart(2, '0');
      const month = String(utcDate.getMonth() + 1).padStart(2, '0');
      const year = utcDate.getFullYear();
      const hour = String(utcDate.getHours()).padStart(2, '0');
      const minute = String(utcDate.getMinutes()).padStart(2, '0');


      return `${day}/${month}/${year} ${hour}:${minute} CET`;
    },
    showRejectModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async handleReject() {
      if (!this.selectedReason) {
        this.$toastr.e('Please select a reason for declining the offer.');
        return;
      }
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/projects/open/decline/${this.$route.params.token}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            status: 7,
            reason_for_decline: this.selectedReason.value
          })
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // const data = await response.json();
        // console.log('Offer declined successfully:', data);
        this.$emit('rejectionSuccess');
        this.closeModal();
      } catch (error) {
        //console.error('Error declining offer:', error);
      }
    },
    showSizeSplitModal(product) {
      this.currentProduct = product;
      this.showSizeSplit = true;
      this.sizeSplit = product.split_sizes.reduce((acc, size) => {
        acc[size.size] = size.quantity;
        return acc;
      }, {});
    },
    validateSizeSplits(productId) {
      const product = this.data.products.find(p => p.id === productId);
      const totalOrdered = parseInt(product.order_quantity, 10);
      const totalSplits = Object.values(this.sizeSplit)
          .reduce((total, num) => total + parseInt(num, 10), 0);

      // console.log('Total Ordered:', totalOrdered);
      // console.log('Total Splits:', totalSplits);

      this.splitErrors[productId] = totalSplits === totalOrdered ? '' : `Whoopsie! Quantity across variants needs to equal ${totalOrdered}.`;
    },

    saveSizeSplit(productId) {
      if (!this.splitErrors[productId]) {
        const product = this.data.products.find(p => p.id === productId);

        if (product) {
          product.split_sizes.forEach(size => {
            if (this.sizeSplit.hasOwnProperty(size.size)) {
              size.quantity = this.sizeSplit[size.size];
            }
          });

          // console.log('Size split saved:', product.split_sizes);
        }
        this.closeSizeSplitModal();
      }
    },
    closeSizeSplitModal() {
      this.showSizeSplit = false;
      this.currentProduct = null;
    },
    showDiscountModal(product) {
      this.currentProduct = product;
      this.showDiscounts = true;
    },
    closeDiscountModal() {
      this.showDiscounts = false;
      this.currentProduct = null;
    },
    async closeMockupModal() {
      await this.closeAndRestartData(this.current_variant);
    },
    calculateTotal() {
      let productTotal = this.data.products.reduce((total, product) => {
        let productSubtotal = this.getDiscountedPrice(product) * product.order_quantity;

        let brandingCosts = product.variant_images.reduce((sum, variant) => {
          if (variant.branding_position.chosen_by_customer) {
            sum += parseFloat(variant.branding_position.setup_fee || 0);
            sum += parseFloat(variant.branding_position.price || 0) * product.order_quantity;
          }
          return sum;
        }, 0);

        return total + productSubtotal + brandingCosts;
      }, 0);

      let lineItemTotal = this.data.line_items.reduce((total, item) => {
        return total + parseFloat(item.unit_price) * item.quantity;
      }, 0);

      const totalWithVAT = (productTotal + lineItemTotal) * (100 + this.data.vat_percentage) / 100;

      return totalWithVAT.toFixed(2);
    },
    getDeliveryTerms(termCode) {
      const DELIVERY_TERMS = [
        {code: 1, description: "DAP (Delivery at Place)"},
        {code: 2, description: "EXW (Ex Works)"}
      ];
      const term = DELIVERY_TERMS.find(t => t.code === termCode);
      return term ? term.description : "Unknown delivery term";
    },
    getPaymentTerms(termCode) {
      const PROJECT_PAYMENT_TERMS = [
        {code: 1, description: "50% up front, 50% after delivery"},
        {code: 2, description: "14 days net"},
        {code: 3, description: "30 days net"},
        {code: 4, description: "45 days net"},
        {code: 5, description: "60 days net"},
        {code: 6, description: "Payment in advance"}
      ];

      const term = PROJECT_PAYMENT_TERMS.find(t => t.code === termCode);
      return term ? term.description : "Unknown payment term";
    },
    uploadSVGLogo(brandingMethodId) {
      this.brandingMethodSelectedDuringFileUploadId = brandingMethodId;

      this.$refs.fileInput.click();

    },
    handleFileUpload(event) {
      const file = event.target.files[0];

      if (file && (file.type === "image/svg+xml" || file.type === "application/pdf")) {
        const formData = new FormData();
        formData.append("branding_method_id", this.brandingMethodSelectedDuringFileUploadId);
        formData.append("artwork_file", file);

        const token = this.data.token;
        const url = `${process.env.VUE_APP_API_URL}/api/projects/open/upload-artwork-file/${token}/`;

        fetch(url, {
          method: "PUT",
          body: formData,
        })
            .then(response => response.json())
            .then(async data => {
              await this.clearSavedProperties();

              await this.clearVariantProperties(this.current_variant);
              await new Promise(resolve => setTimeout(resolve, 300));
              if (data.status == "file uploaded") {

                // console.log(data)
                const logoLink = data.logo_link;
                this.updateLogoLink(this.brandingMethodSelectedDuringFileUploadId, logoLink);
              } else {
                //console.error("Failed to upload the artwork file! neew", data);
              }
            })
            .catch(() => {
              //console.error("Error uploading the artwork file:", error);
            });
      } else {
        this.$toastr.e("Please upload a valid SVG or PDF file (max. 1 page)");
      }
    },
    validateSVG(logolink) {
      return fetch(logolink)
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to fetch file.');
            }
            return response.text();
          })
          .then(svgContent => {
            var parser = new DOMParser();
            var doc = parser.parseFromString(svgContent, 'image/svg+xml');
            var parseError = doc.querySelector('parsererror');
            if (parseError) {
              //console.error('SVG Parse Error: ', parseError.textContent);
              return false;
            } else {
              return true;
            }
          })
          .catch(() => {
            //console.error('Error:', error);
            return false;
          });
    },
    updateLogoLink(brandingMethodId, logoLink) {
      this.validateSVG(logoLink).then(isValid => {
        if (!isValid) {
          this.$toastr.e('Please upload a valid SVG or PDF file (max. 1 page)');
        } else {
          this.data.products.forEach(product => {
            product.variant_images.forEach(variantImage => {
              if (variantImage.branding_position.branding_method_id === brandingMethodId) {
                variantImage.branding_position.logo_link = logoLink;

                if (this.svgLogo) {
                  this.svgLogo.destroy();
                  this.svgLogo = null;
                }
                if (this.transformer) {
                  this.transformer.destroy();
                  this.transformer = null;
                }

                this.layer.draw();
                this.displayLogoOnCanvas(logoLink, variantImage);
              }
            });
          });
        }
      });

    },
    async displayLogoOnCanvas(logoLink, passedVariant) {
      await this.clearSavedProperties();
      const img = new Image();

      img.crossOrigin = 'Anonymous';
      img.src = logoLink;

      this.current_variant = passedVariant;

      img.onload = () => {

        if (!this.layer || !this.stage) {
          //console.error("Stage or layer not initialized");
          return;
        }

        this.$nextTick(() => {
          const variantImage = passedVariant;

          if (!variantImage) {
            //console.error('Variant image not found for branding method ID:', passedVariant.branding_position.branding_method_id);
            return;
          }

          if (this.svgLogo) {
            this.svgLogo.destroy();
            this.svgLogo = null;
          }
          if (this.transformer) {
            this.transformer.destroy();
            this.transformer = null;
          }


          const areRequiredPropsAvailable = this.hasVariantAllRequiredProperties(variantImage)

          let initialScale;
          if (areRequiredPropsAvailable) {
            this.loadSavedProperties(variantImage)
            initialScale = this.current_logo_scale;
          } else {
            const maxLogoWidth = this.rectWidth / 2.5;
            const maxLogoHeight = this.rectHeight / 2.5;
            const widthScale = maxLogoWidth / img.width;
            const heightScale = maxLogoHeight / img.height;
            initialScale = Math.min(widthScale, heightScale);


            this.previous_scale = initialScale;
            this.current_logo_scale = initialScale;
            this.current_logo_original_width = img.width;
            this.current_logo_original_height = img.height;


            const minScaleFactor = 0.6;
            this.current_logo_minimum_scale = initialScale * minScaleFactor;

            const maxLogoWidthInRect = this.rectWidth;
            const maxLogoHeightInRect = this.rectHeight;
            const widthScaleInRect = maxLogoWidthInRect / img.width;
            const heightScaleInRect = maxLogoHeightInRect / img.height;

            this.current_logo_maximum_scale = Math.min(widthScaleInRect, heightScaleInRect);
          }

          const logoWidth = img.width * initialScale;
          const logoHeight = img.height * initialScale;


          let initialX = this.current_logo_x_axis ? this.current_logo_x_axis : this.rectX + 1;
          let initialY = this.current_logo_y_axis ? this.current_logo_y_axis : this.rectY + 1;


          const konvaLogo = new Konva.Image({
            image: img,
            x: initialX,
            y: initialY,
            width: logoWidth,
            height: logoHeight,
            rotation: this.current_logo_angle ? this.current_logo_angle : this.rectAngle,
            draggable: true,
            opacity: 0.85,
          });


          this.layer.add(konvaLogo);


          this.previous_scale = this.current_logo_scale;
          this.current_logo_scale = konvaLogo.width() / this.current_logo_original_width;
          this.current_logo_x_axis = konvaLogo.x();
          this.current_logo_y_axis = konvaLogo.y();
          this.current_logo_x_width = konvaLogo.width();
          this.current_logo_y_width = konvaLogo.height();
          this.current_logo_angle = konvaLogo.rotation();


          const factor = this.current_logo_x_width / variantImage.width;

          let naturalFactor = 1 / this.naturalImageWidthScale
          this.current_logo_width_mm = factor * this.current_variant.branding_position.width_mm * naturalFactor;


          const transformer = new Konva.Transformer({
            flipEnabled: false,
            enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            nodes: [konvaLogo],
            boundBoxFunc: (oldBox, newBox) => {
              const logoWidth = newBox.width;
              const logoHeight = newBox.height;

              const logoTopLeft = {x: newBox.x, y: newBox.y};
              const logoTopRight = this.getRotatedPoint(newBox.x + logoWidth, newBox.y, newBox.x, newBox.y, konvaLogo.rotation());
              const logoBottomLeft = this.getRotatedPoint(newBox.x, newBox.y + logoHeight, newBox.x, newBox.y, konvaLogo.rotation());
              const logoBottomRight = this.getRotatedPoint(newBox.x + logoWidth, newBox.y + logoHeight, newBox.x, newBox.y, konvaLogo.rotation());

              let isWithinBounds = this.isPointInsideRectangle(logoTopLeft, this.rectangleReference) &&
                  this.isPointInsideRectangle(logoTopRight, this.rectangleReference) &&
                  this.isPointInsideRectangle(logoBottomLeft, this.rectangleReference) &&
                  this.isPointInsideRectangle(logoBottomRight, this.rectangleReference);



              return isWithinBounds ? newBox : oldBox;
            },
          });

          transformer.flipEnabled(false);

          this.currentTransformer = transformer;
          this.layer.add(transformer);
          this.layer.draw();

          this.prevPosition = {x: konvaLogo.x(), y: konvaLogo.y()};
          konvaLogo.on('transform', () => {
            const MIN_LOGO_WIDTH = this.current_logo_original_width * this.current_logo_minimum_scale;
            const MIN_LOGO_HEIGHT = this.current_logo_original_height * this.current_logo_minimum_scale;

            const MAX_LOGO_WIDTH = this.current_logo_original_width * this.current_logo_maximum_scale;
            const MAX_LOGO_HEIGHT = this.current_logo_original_height * this.current_logo_maximum_scale;

            let width = konvaLogo.width() * konvaLogo.scaleX();
            let height = konvaLogo.height() * konvaLogo.scaleY();

            let scaleX = konvaLogo.scaleX();
            let scaleY = konvaLogo.scaleY();

            let shouldResetPosition = false;

            if (width < MIN_LOGO_WIDTH || height < MIN_LOGO_HEIGHT) {
              if (width < MIN_LOGO_WIDTH) {
                scaleX = MIN_LOGO_WIDTH / konvaLogo.width();
              }
              if (height < MIN_LOGO_HEIGHT) {
                scaleY = MIN_LOGO_HEIGHT / konvaLogo.height();
              }
              shouldResetPosition = true;
            }

            if (width > MAX_LOGO_WIDTH || height > MAX_LOGO_HEIGHT) {
              if (width > MAX_LOGO_WIDTH) {
                scaleX = MAX_LOGO_WIDTH / konvaLogo.width();
              }
              if (height > MAX_LOGO_HEIGHT) {
                scaleY = MAX_LOGO_HEIGHT / konvaLogo.height();
              }
              shouldResetPosition = true;
            }

            if (shouldResetPosition) {
              const newScale = Math.max(scaleX, scaleY);
              konvaLogo.scaleX(newScale);
              konvaLogo.scaleY(newScale);

              konvaLogo.x(this.prevPosition.x);
              konvaLogo.y(this.prevPosition.y);
            } else {
              konvaLogo.width(width);
              konvaLogo.height(height);

              konvaLogo.scaleX(1);
              konvaLogo.scaleY(1);
            }

            this.previous_scale = this.current_logo_scale;
            this.current_logo_scale = konvaLogo.width() / this.current_logo_original_width;
            this.current_logo_x_axis = konvaLogo.x();
            this.current_logo_y_axis = konvaLogo.y();
            this.current_logo_x_width = konvaLogo.width();
            this.current_logo_y_width = konvaLogo.height();
            this.current_logo_angle = konvaLogo.rotation();

            // console.log("Logo Scale: ", this.current_logo_scale);
            // console.log("X Axis: ", this.current_logo_x_axis);
            // console.log("Y Axis: ", this.current_logo_y_axis);
            // console.log("Logo Width: ", this.current_logo_x_width);
            // console.log("Logo Height: ", this.current_logo_y_width);
            // console.log("Rotation Angle: ", this.current_logo_angle);

            const factor = this.current_logo_x_width / variantImage.width;

            let naturalFactor = 1 / this.naturalImageWidthScale

            this.current_logo_width_mm = factor * this.current_variant.branding_position.width_mm * naturalFactor;


            this.prevPosition = {
              x: konvaLogo.x(),
              y: konvaLogo.y()
            };


            this.layer.batchDraw();
          });

          konvaLogo.on('dragmove', () => {
            const pos = konvaLogo.position();
            const logoWidth = konvaLogo.width();
            const logoHeight = konvaLogo.height();

            const logoTopLeft = {x: pos.x, y: pos.y};
            const logoTopRight = this.getRotatedPoint(pos.x + logoWidth, pos.y, pos.x, pos.y, konvaLogo.rotation());
            const logoBottomLeft = this.getRotatedPoint(pos.x, pos.y + logoHeight, pos.x, pos.y, konvaLogo.rotation());
            const logoBottomRight = this.getRotatedPoint(pos.x + logoWidth, pos.y + logoHeight, pos.x, pos.y, konvaLogo.rotation());

            const isWithinBounds = this.isPointInsideRectangle(logoTopLeft, this.rectangleReference) &&
                this.isPointInsideRectangle(logoTopRight, this.rectangleReference) &&
                this.isPointInsideRectangle(logoBottomLeft, this.rectangleReference) &&
                this.isPointInsideRectangle(logoBottomRight, this.rectangleReference);

            if (!isWithinBounds) {
              konvaLogo.position(this.prevPosition);
            } else {
              this.prevPosition = {x: pos.x, y: pos.y};


              this.current_logo_x_axis = konvaLogo.x();
              this.current_logo_y_axis = konvaLogo.y();
              this.current_logo_x_width = konvaLogo.width();
              this.current_logo_y_width = konvaLogo.height();
              this.current_logo_angle = konvaLogo.rotation();


              const factor = this.current_logo_x_width / variantImage.width;

              let naturalFactor = 1 / this.naturalImageWidthScale

              this.current_logo_width_mm = factor * this.current_variant.branding_position.width_mm * naturalFactor;

            }
          });

          this.svgLogo = konvaLogo;
          this.transformer = transformer;
        });
      };

      img.onerror = () => {
        //console.error("Image failed to load:", error);
      };
    },
    isPointInsideRectangle(point, konvaRect) {

      const localPoint = konvaRect.getTransform().copy().invert().point(point);

      const width = konvaRect.width()
      const height = konvaRect.height();

      return (
          localPoint.x >= -2 && localPoint.x <= width + 2 &&
          localPoint.y >= -2 && localPoint.y <= height + 2
      );
    },
    getRotatedPoint(x, y, cx, cy, angle) {
      const radians = angle * Math.PI / 180;
      const dx = x - cx;
      const dy = y - cy;
      return {
        x: cx + dx * Math.cos(radians) - dy * Math.sin(radians),
        y: cy + dx * Math.sin(radians) + dy * Math.cos(radians)
      };
    },
    isPointInPolygon(point, polygon) {
      const {x, y} = point;
      let inside = false;
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x, yi = polygon[i].y;
        const xj = polygon[j].x, yj = polygon[j].y;
        const intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
      }
      return inside;
    },
    async onImageLoad(variant) {
      await new Promise(resolve => setTimeout(resolve, 300));

      variant = this.current_variant;

      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = this.currentImage + '?v=1';

      image.onload = () => {
        this.imageLoaded = true;

        if (this.stage) {
          this.stage.destroy();
          this.stage = null;
        }

        if (this.layer) {
          this.layer.destroy();
          this.layer = null;
        }

        const containerWidth = this.$refs.konvaContainer.offsetWidth;
        const containerHeight = containerWidth;

        const stage = new Konva.Stage({
          container: this.$refs.konvaContainer,
          width: containerWidth,
          height: containerHeight,
        });

        const layer = new Konva.Layer();
        stage.add(layer);

        const konvaImage = new Konva.Image({
          image: image,
          x: 0,
          y: 0,
          width: containerWidth,
          height: containerHeight,
          crossOrigin: "Anonymous",
        });

        layer.add(konvaImage);

        const {x_axis, y_axis, width, height, angle} = variant;

        this.naturalImageWidthScale = containerWidth / image.naturalWidth;
        this.naturalImageHeightScale = containerHeight / image.naturalHeight;

        this.rectX = x_axis * this.naturalImageWidthScale;
        this.rectY = y_axis * this.naturalImageHeightScale;
        this.rectWidth = width * this.naturalImageWidthScale;
        this.rectHeight = height * this.naturalImageHeightScale;
        this.rectAngle = angle;

        const konvaRect = new Konva.Rect({
          x: this.rectX,
          y: this.rectY,
          width: this.rectWidth,
          height: this.rectHeight,
          fill: 'transparent',
          stroke: '#FF00FF',
          strokeWidth: 2,
          rotation: this.rectAngle,
          draggable: false,
        });

        this.rectangleReference = konvaRect;

        layer.add(konvaRect);
        layer.draw();

        this.stage = stage;
        this.layer = layer;
      };


    }
  },

  watch: {
    currentImage(newSrc) {
      this.data.freshDataSrc = newSrc;
      if (!this.showDiscounts && !this.showSizeSplit) {
        this.onImageLoad();
      }
    },
    'data.products': {
      handler(products) {
        products.forEach(product => {
          this.$watch(() => product.order_quantity, () => {
            this.updateSizeSplit(product);
          });
        });
      },
      deep: false,
      immediate: true
    },
    'sizeSplit': {
      handler() {
        if (this.currentProduct) {
          this.validateSizeSplits(this.currentProduct.id);
        }
      },
      deep: true
    },
    activeTab(newIndex) {
      this.$nextTick(() => {
        setTimeout(async () => {
          // console.log("Current product", this.currentProduct)
          if (this.currentTransformer) {
            this.currentTransformer.destroy();
            this.currentTransformer = null;
          }
          if (this.rectangleReference) {
            this.rectangleReference.destroy();
            this.rectangleReference = null;
          }

          const dataURL = await this.stage.toDataURL({
            mimeType: 'image/png',
            quality: 1.0
          });

          const newThumbnailFile = this.dataURLtoFile(dataURL, 'product-thumbnail.png');
          this.currentProduct.product_thumb_alt = URL.createObjectURL(newThumbnailFile);
          await this.uploadImage(newThumbnailFile, this.current_variant.branding_position.branding_method_id);
          await this.savePropsInData(this.current_variant);
          const selectedVariant = this.filteredVariants[newIndex];
          this.current_variant = selectedVariant;
          await this.onImageLoad(selectedVariant);
          if (selectedVariant && selectedVariant.branding_position.logo_link) {
            await this.displayLogoOnCanvas(selectedVariant.branding_position.logo_link, selectedVariant);
          }
        }, 200);
      });
    }
  },
  computed: {
    currentImage() {
      const filteredVariants = this.filteredVariants;
      return this.currentProduct && filteredVariants && filteredVariants[this.activeTab]
          ? filteredVariants[this.activeTab].image
          : '';
    },
    filteredVariants() {
      if (!this.currentProduct || !this.currentProduct.variant_images) {
        return [];
      }

      return this.currentProduct.variant_images.filter(variant => {
        return variant.branding_position.chosen_by_customer;
      })
    },
    allLogosUploaded() {

      let allUploaded = true;


      this.data.products.forEach((product) => {

        product.variant_images.forEach((variant) => {

          if (
              variant.branding_position.chosen_by_customer &&
              variant.branding_position.name !== "No branding" &&
              !variant.branding_position.logo_link
          ) {

            allUploaded = false;
          }
        });
      });

      return allUploaded;
    },
    mockupLinksByProduct() {
      const mockupLinks = {};

      // console.log("recomputation!")

      this.data.products.forEach(product => {
        if (product.variant_images) {
          const links = product.variant_images
              .filter(variant => variant.branding_position.chosen_by_customer)
              .map(variant => variant?.branding_position?.mockup_link)
              .filter(link => link);
          if (links.length > 0) {
            mockupLinks[product.id] = links;
          }
        }
      });

      return mockupLinks;
    },
    minDesiredDeliveryDate() {
      const today = new Date()
      return today.toISOString().split('T')[0]
    }
  }
};
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.animated-container {
  padding-top: 0;
}

.variant-name {
  display: block;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1px;
  color: #A7A7A7;
  margin-bottom: 10px;
}

.product-title {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1px;
}

.comment-date {
  font-family: 'Volte-Semibold';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A7A7A7;
  margin-bottom: 0;
}

.border-bottom {
  border-bottom: 1px solid #EAEAEA;
}

.summary-section {
  background-color: #EAEAEA;
}

.summary-section h1 {
  margin-bottom: 20px;
}

.custom-button {
  color: white;
  border-radius: 3px;
  padding: 12px 0;
}

.custom-button.btn-success {
  background-color: #8CCD8C;
  border-color: #8CCD8C;
}

.custom-button.btn-danger {
  background-color: #F07878;
  border-color: #F07878;
}

.total-text {
  font-weight: 700;
  font-size: 24px;
}

.sam-top-border {
  border-top: 1px solid #E4E4E4;
}

.error-message {
  color: red;
}


</style>
